import React from 'react'
import {Route, Switch, Link} from "react-router-dom"
import {Typography} from '@material-ui/core'
import Navbar from "./Components/NavBar"
import Login from "./Components/Pages/Login"
import About from "./Components/Pages/About"
import Messages from "./Components/Pages/Messages"
import CarrierMap from "./Components/Pages/CarrierMap"
import Page404 from "./Components/Pages/Page404"
import Footer from "./Components/Footer"
import { ConfirmProvider } from 'material-ui-confirm';
import PortManager from "./Components/Pages/PortManager"
import Campaigns from "./Components/Pages/Campaigns"
import Gateway from "./Components/Pages/Gateway"
import SlimsDomains from './Components/Pages/SlimsDomains'
import SlimsDomainsActiveDomains from './Components/Pages/SlimsDomainsActiveDomains'



function Copyright() {
  return (
    <Typography variant="body1" color="inherit">
      {'Copyright © '}
      <Link style={{color: "orange"}} to={{ pathname: "https://goip-portal.nexapi.net" }} target="_blank">
        Goip Manager Portal
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const App = () => {
  // const location = useLocation()
  return (
    <React.Fragment>
      
      {/* {location.pathname !== "/" && <Navbar />} */}
      <Navbar />
      <ConfirmProvider>
      <Switch>
      
        <Route exact path="/" component={Login} />
        <Route exact path="/about" component={About} />
        <Route exact path="/campaigns" component={Campaigns} />
        <Route exact path="/messages" component={Messages} />
        <Route exact path="/carrier-mapping" component={CarrierMap} />
        <Route exact path="/port-manager" component={PortManager} />
        <Route exact path="/gateway-settings" component={Gateway} />
        <Route exact path="/slims-domains" component={SlimsDomains} />
        <Route exact path="/slims-domains-manager" component={SlimsDomainsActiveDomains} />
        <Route component={Page404} />
        
      </Switch>
      </ConfirmProvider>
      <Footer><span><Copyright/></span></Footer>
      
    </React.Fragment>
  )
}

export default App
